import {
  Box,
  Container,
  Flex,
  useToast,
  useDisclosure,
  Text,
  Button,
  Spinner,
} from '@chakra-ui/react';
import {
  AddListingBanner,
  CategoryCards,
  SponsorsCard,
  LoginPrompt,
  ErrorMessageBox,
} from 'src/components/atoms';
import {
  ListingAttributes,
  ListingsView,
  EmailListModal,
  FeaturedListingsBar,
} from 'src/components/organisms';
import {
  MarketplaceInfo,
  RootCategory,
  PaginatedListingsResponse,
  SiteAndId,
} from 'src/api/v1-api';
import { CONTAINER_MAX_WIDTH_1, CONTAINER_MAX_WIDTH_2 } from 'src/constants/ui';
import { HeroCard, Announcement } from 'src/components/molecules';
import { useEffect, useState, useRef, useMemo } from 'react';
import { AttributeSelectorProvider } from 'src/contexts/AttributeSelector';
import { useRouter } from 'next/router';
import { NextSeo } from 'next-seo';
import { GetStaticPropsContext } from 'next';
import { srcFromCDN } from 'src/utils/files';
import { checkCategoryPermissions } from 'src/utils/categoryUtils';
import { useAuth } from 'src/utils/auth';
import { LISTINGS_PER_PAGE, MAP_LISTINGS_PER_PAGE } from 'src/constants/marketplace';
import { getMarketplaceUrl } from 'src/utils/marketplaceUtils';
import Head from 'next/head';

interface Props {
  category: RootCategory;
  marketplace: MarketplaceInfo;
  listings: PaginatedListingsResponse;
}

const HomeScreen = ({ category, marketplace, listings }: Props) => {
  const router = useRouter();
  const toast = useToast();
  const toastShownRef = useRef(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { user, hasRoles, userLoaded } = useAuth();
  const [hasPermission, setHasPermission] = useState<boolean>(true);
  const [permissionChecked, setPermissionChecked] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>('');

  const attributes = useMemo(() => category?.attributes ?? [], [category]);
  const sortOptions = useMemo(() => category?.functional?.sort_options ?? [], [category]);
  const listingsUrl = useMemo(
    () => `${process.env.NEXT_PUBLIC_API_HOST}/listings/category/${category?.id}`,
    [category],
  );
  const mapFetchUrl = useMemo(
    () => `${process.env.NEXT_PUBLIC_API_HOST}/listings/map/${category?.id}/`,
    [category],
  );

  useEffect(() => {
    if (router.isReady && !toastShownRef.current) {
      if (router.query.order_success === 'true') {
        toast({
          title: 'Order successful',
          description:
            'Your order has been successfully processed. Please check your email for more information.',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      } else if (router.query.success === 'true') {
        toast({
          title: 'Success',
          description: router.query.message as string,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      } else if (router.query.success === 'false') {
        toast({
          title: 'Error',
          description: router.query.message as string,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
      toastShownRef.current = true;
    }
  }, [router.isReady, router.query.order_success, router.query.success, toast]);

  useEffect(() => {
    if (router.isReady && router.query.showEmailListModal === 'true') {
      onOpen();
    }
  }, [router.isReady, router.query.showEmailListModal, toast, onOpen]);

  const [announcementOpened, setAnnouncementOpened] = useState(true);
  const [userChangedPriceRange, handleUserChangedPriceRange] = useState(false);
  const showAnnouncement = category?.functional?.announcement && announcementOpened;
  const inventoryDisplay = category?.functional?.inventory_display;

  useEffect(() => {
    const checkPermissions = async () => {
      const { hasPermission, errorMessage } = await checkCategoryPermissions(
        category?.id,
        marketplace?.id,
        user,
        hasRoles,
      );
      setHasPermission(hasPermission);
      setErrorMessage(errorMessage);
      setPermissionChecked(true);
    };

    if (userLoaded) {
      checkPermissions();
    }
  }, [category, marketplace, user, userLoaded]);

  const marketplaceUrl = getMarketplaceUrl(marketplace);
  const marketplaceLogoImage = marketplace?.logo_image
    ? srcFromCDN(marketplace.logo_image, 300)
    : '';

  const jsonLd = {
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    name: marketplace?.name,
    description: marketplace?.description,
    url: marketplaceUrl,
  };

  if (!marketplace || !category) {
    return null;
  }

  return (
    <>
      <Head>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(jsonLd),
          }}
        />
      </Head>
      <NextSeo
        title={marketplace?.name}
        description={marketplace?.description}
        canonical={marketplaceUrl}
        openGraph={{
          title: marketplace?.name,
          description: marketplace?.description,
          url: marketplaceUrl,
          type: 'website',
          siteName: marketplace?.name,
          site_name: marketplace?.name,
          images: [
            {
              url: marketplaceLogoImage,
              width: 300,
              height: 300,
              alt: marketplace?.name,
            },
          ],
        }}
        twitter={{
          site: marketplaceUrl,
          cardType: 'summary_large_image',
        }}
      />

      <AddListingBanner page="home" />
      {showAnnouncement && (
        <Container maxW={CONTAINER_MAX_WIDTH_1} pt={{ base: '8px', lg: '12px' }}>
          <Announcement
            text={category?.functional?.announcement ?? ''}
            onClose={() => setAnnouncementOpened(false)}
          />
        </Container>
      )}
      <SponsorsCard category={category} />
      <Container
        maxW={CONTAINER_MAX_WIDTH_1}
        pt={{ base: 4, md: 4, lg: 6 }}
        display="flex"
        flexDirection="column"
      >
        {category?.functional?.hero_card &&
          Object.keys(category.functional.hero_card).length > 0 && (
            <Box pb={{ base: 4, md: 5, lg: 6 }}>
              <HeroCard
                title={category.functional.hero_card.hero_card_title}
                description={category.functional.hero_card.hero_card_description}
                imageURL={category.functional.hero_card.hero_card_image_url}
                mobileImageURL={category.functional.hero_card.hero_card_mobile_image_url}
                cta={{
                  label: category.functional.hero_card.hero_card_cta,
                  link: category.functional.hero_card.hero_card_link,
                }}
                text_box_opacity={category.functional.opacity}
                isSimple={category.functional.hero_card?.hero_card_is_simple}
              />
            </Box>
          )}
        <Flex w="full" direction="column" rowGap={{ base: 12, md: 14, lg: 16 }}>
          <CategoryCards category={category} />

          {!permissionChecked ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              py={8}
              backgroundColor="rgba(255, 255, 255, 0.7)"
            >
              <Spinner />
            </Box>
          ) : (
            <>
              {hasPermission ? (
                <Flex direction="column" gap={{ base: 3, md: 4, lg: 5 }}>
                  <AttributeSelectorProvider
                    attributes={attributes}
                    useToggle={
                      category?.functional?.map === true &&
                      category?.functional?.inventory_display !== 'map'
                    }
                    defaultViewType={
                      category?.functional?.map && category?.functional?.map_view ? 'map' : 'grid'
                    }
                    maxPrice={
                      category?.functional?.filter_max_price ??
                      category?.functional?.computed_filter_max_price
                    }
                    sortOptions={sortOptions}
                  >
                    {category?.functional?.has_featured_bar && (
                      <FeaturedListingsBar category={category} />
                    )}
                    {inventoryDisplay && inventoryDisplay !== 'None' && (
                      <ListingAttributes
                        category={category}
                        handleUserChangedPriceRange={handleUserChangedPriceRange}
                      />
                    )}
                    <ListingsView
                      propListings={listings}
                      fetchUrl={listingsUrl}
                      initialMapFetchUrl={mapFetchUrl}
                      inventoryDisplay={category?.functional?.inventory_display}
                      paginationStyle={category?.functional?.pagination_style}
                      resultsPerPage={
                        category?.functional?.results_per_page
                          ? category.functional.results_per_page
                          : LISTINGS_PER_PAGE
                      }
                      mapResultsPerPage={
                        category?.functional?.map_results_per_page
                          ? category.functional.map_results_per_page
                          : MAP_LISTINGS_PER_PAGE
                      }
                      category={category}
                      userChangedPriceRange={userChangedPriceRange}
                    />
                  </AttributeSelectorProvider>
                </Flex>
              ) : user ? (
                <Container maxW={CONTAINER_MAX_WIDTH_2} mt={10}>
                  <ErrorMessageBox>
                    {errorMessage}
                    &nbsp;You can add roles to your account from the&nbsp;
                    <Button variant="link" onClick={() => router.push('/account/info')}>
                      <Text as="span" textDecoration="underline">
                        My Account
                      </Text>
                    </Button>
                    &nbsp;page.
                  </ErrorMessageBox>
                </Container>
              ) : (
                <LoginPrompt message="to view listings." showWithSignup={true} />
              )}
            </>
          )}
        </Flex>
      </Container>
      <EmailListModal isOpen={isOpen} onClose={onClose} />
    </>
  );
};

export async function getStaticProps(context: GetStaticPropsContext) {
  const hostname = context.params?.site;
  if (!hostname) {
    return {
      notFound: true,
    };
  }

  try {
    const rootCategoryResponse = await fetch(
      `${process.env.NEXT_PUBLIC_API_HOST}/marketplaces/${hostname}/`,
    );

    if (!rootCategoryResponse.ok) {
      return {
        notFound: true,
      };
    }

    const rootCategoryPayload = await rootCategoryResponse.json();

    if (Object.keys(rootCategoryPayload).length === 0) {
      return {
        notFound: true,
      };
    }

    let category = {};
    for (const key in rootCategoryPayload) {
      if (key !== 'marketplace' && key !== 'listings') {
        category = { ...category, [key]: rootCategoryPayload[key] };
      }
    }

    const marketplace = rootCategoryPayload['marketplace'];
    const listings = rootCategoryPayload['listings'] || {};

    return {
      props: {
        category,
        marketplace,
        listings,
      },
      revalidate: 10,
    };
  } catch (error) {
    return {
      notFound: true,
    };
  }
}

export async function getStaticPaths() {
  try {
    const res = await fetch(`${process.env.NEXT_PUBLIC_API_HOST}/active_top_level_category_ids/`);
    if (!res.ok) {
      return { paths: [], fallback: true };
    }

    const data = await res.json();

    // Deduplicate by site, keeping the latest ID
    const siteMap = new Map();
    data.forEach((item: SiteAndId) => {
      if (!siteMap.has(item.site) || siteMap.get(item.site).id < item.id) {
        siteMap.set(item.site, item);
      }
    });

    const paths = Array.from(siteMap.values())
      .filter(
        (item: SiteAndId) =>
          item.site !== null &&
          item.site !== undefined &&
          item.id !== null &&
          item.id !== undefined,
      )
      .map((item: SiteAndId) => ({
        params: {
          site: item.site.toString(),
          id: item.id.toString(),
        },
      }));

    return {
      paths,
      fallback: 'blocking',
    };
  } catch (error) {
    return { paths: [], fallback: 'blocking' };
  }
}

export default HomeScreen;
